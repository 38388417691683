<template>

    <div v-show="!closed" class="alert" :class="classObj" @click="$emit('click')">
        <div v-if="label" class="alert-title">
            <div class="alert-icon" :class="classObj">
                <icon :icon="selectedIcon.name" size="large" />
            </div>
            <div class="alert-title-text">
                <p class="alert-label" v-html="label" />
                <a v-if="link" target="_blank" :href="link.url" class="alert-link">
                    {{ link.text }}
                </a>
            </div>
            <button v-if="closable" class="alert-close" @click.stop="close()">
                <icon icon="times-solid" size="tiny" />
            </button>
        </div>
        <div v-if="message || primaryAction" class="alert-content" :class="withoutLabel">
            <p v-if="message" class="alert-message" v-html="message" />
            <div v-if="primaryAction" class="alert-actions">
                <a :href="primaryAction.url" class="alert-action" target="_blank" @click.stop="confirmPrimaryAction" v-html="primaryAction.text" />
                <a v-if="secondaryAction" :href="secondaryAction.url" target="_blank" class="alert-action" @click.stop="confirmSecondaryAction" v-html="secondaryAction.text" />
            </div>
        </div>
        
    </div>

</template>

<script>

export default {
    name: 'Alert',

    props: {
        label: {
            type: String
        },
        type: {
            type: String,
            required: true
        },
        message: {
            type: String
        },
        link: {
            type: Object,
            validator(obj) {
                return 'text' in obj && 'url' in obj
            }
        },
        primaryAction: {
            type: Object,
            validator(obj) {
                return 'text' in obj
            }
        },
        secondaryAction: {
            type: Object,
            validator(obj) {
                return 'text' in obj
            }
        },
        closable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            selectedIcon: null,
            icons: [
                {
                    type: 'info',
                    name: 'info-circle-solid'
                },
                {
                    type: 'warning',
                    name: 'exclamation-triangle-solid'
                },
                {
                    type: 'error',
                    name: 'times-circle-solid'
                },
                {
                    type: 'success',
                    name: 'check-circle-solid'
                }
            ],
            closed: false
        }
    },

    computed: {
        classObj() {
            return {
                'alert-info': this.selectedIcon.type === 'info',
                'alert-warning': this.selectedIcon.type === 'warning',
                'alert-error': this.selectedIcon.type === 'error',
                'alert-success': this.selectedIcon.type === 'success'
            }
        },
        withoutLabel() {
            return {
                'no-margin': !this.label
            }
        }
    },

    watch: {
        $props: {
            immediate: true,
            handler() {
                this.validateProps
            }
        }
    },

    created() {
        this.selectedIcon = this.icons.find((x) => x.type === this.type)
    },

    methods: {
        validateProps() {
            if(!this.label && !this.message)
                throw Error('Required field missing.')
        },
        confirmPrimaryAction() {
            this.$emit('confirmPrimary')
        },

        confirmSecondaryAction() {
            this.$emit('confirmSecondary')
        },
        close() {
            this.closed = true
            this.$emit('removeMessage')
        }
    }

}
</script>

<style lang="scss" scoped>

    @import '~sass/variables.scss';

    .alert {
        padding: 20px 16px;
        border: 1px solid $grey-90;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        width: 100%;

        .alert-title {
            display: flex;
            align-items: center;

            .alert-icon {
                margin-right: 16px;
            
                &.alert-info {
                    color: $info-30;
                }
            
                &.alert-warning {
                    color: $warning-30;
                }
            
                &.alert-error {
                    color: $error-30;
                }
            
                &.alert-success {
                    color: $success-30;
                }
            }

            .alert-title-text {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .alert-label {
                    font-size: 15px;
                    font-weight: 500;
                }
                
                .alert-link {
                    font-size: 15px;
                    font-weight: 500;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .alert-close {
                margin-left: 6px;
            }
        }

        .alert-content {
            margin: 8px 0 0 36px;

            &.no-margin {
                margin: 0;
            }

            .alert-action {
                font-size: 14px;
                font-weight: 500;
                text-decoration: underline;
                margin-right: 24px;
                cursor: pointer;
            }
        
            .alert-message {
                font-size: 14px;
                font-weight: 400;

                ::v-deep a {
                    color: inherit;
                    font-weight: 500;
                    text-decoration: underline;
                }
            }

            .alert-actions {
                margin-top: 8px;
            }
        }
        
        &.alert-success {
            background-color: $success-10;
            border-color: $success-20;
            color: $success-40;

            a {
                color: $success-40;
            }
        }

        &.alert-error {
            background-color: $error-10;
            border-color: $error-20;
            color: $error-40;

            a {
                color: $error-40;
            }
        }

        &.alert-warning {
            background-color: $warning-10;
            border-color: $warning-20;
            color: $warning-40;

            a {
                color: $warning-40;
            }
        }

        &.alert-info {
            background-color: $info-10;
            border-color: $info-20;
            color: $info-40;

            a {
                color: $info-40;
            }
        }
    }

</style>