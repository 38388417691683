<template>
    <ValidationObserver ref="observer" slim>
        <form class="flex items-center flex-wrap" novalidate @submit.prevent="addTag">
            <span class="txt-13 text-gray-700 mr-2 mt-2 font-semibold">{{ $t('appforms.subscriber.tags') }}:</span>
            <button v-if="!tagFormShown" ref="newTagBtn" class="btn btn-primary radius-2 btn-xs whitespace-nowrap icon-left mt-2 mr-2" @click="showTagForm"><icon size="tiny" icon="plus-solid" />{{ $t('appforms.subscriber.add-tag') }}</button>
            <ValidationProvider v-else rules="required|max:50" v-slot="{ errors }" slim>
                <input ref="newTag" v-model="newTag" v-on-clickaway="hideTagForm" type="text" :placeholder="$t('appforms.subscriber.tag_placeholder')" class="tw-input tw-input-xs mt-2 mr-2 radius-2" :style="{width: tagFormWidth + 'px'}">
                <span v-if="errors.length" class="text-red-500 txt-13 mt-2 mr-2">{{ errors[0] }}</span>
            </ValidationProvider>
            <span v-for="(tag, index) in visibleTags" :key="`tag-${index}`" class="tag sub-tag mr-2 mt-2">{{ tag }} <a href="#" class="text-sail-500 -mr-1 hover:text-sail-700" @click.stop.prevent="removeTag(tag)"><icon :key="`tag-${index}-close`" size="tiny" icon="times-solid" /></a></span>
            <button v-if="thistags.length > 12" type="button" class="more-tags flex items-center mt-2" @click.stop.prevent="showAllTags = !showAllTags">
                <template v-if="showAllTags">
                    <span>{{ $t('appforms.subscriber.fewer_tags') }}</span>
                    <icon key="tags-caret-up" icon="caret-up-solid" size="small" />
                </template>
                <template v-else>
                    <span>{{ $t('appforms.subscriber.more_tags') }}</span>
                    <icon key="tags-caret-down" icon="caret-down-solid" size="small" />
                </template>
            </button>
        </form>
    </ValidationObserver>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {

    name: 'SubscriberTags',

    mixins: [clickaway],

    props: ['email', 'tags'],

    data () {
        return {
            tagFormShown: false,
            tagFormWidth: 100,
            thistags: [],
            newTag: '',
            showAllTags: true
        }
    },

    computed: {
        visibleTags() {
            if(this.showAllTags)
                return this.thistags
            return this.thistags.slice(0, 10)
        }
    },

    created() {
        if(this.tags) {
            this.thistags = this.tags
            if(this.thistags.length > 12)
                this.showAllTags = false
        }
    },

    methods: {
        showTagForm() {
            this.tagFormWidth = parseInt(this.$refs.newTagBtn.clientWidth)
            this.tagFormShown = true
            this.$nextTick(() => {
                this.$refs.newTag.focus()
            })
        },
        hideTagForm() {
            this.tagFormShown = false
        },
        async addTag() {
            if(this.thistags.includes(this.newTag)) {
                this.$message.error(this.$t('appforms.subscriber.duplicate_tag_error'))
                return
            }

            const isValid = await this.$refs.observer.validate()
            if(!isValid) {
                return    
            }

            else {
                try {
                    await this.$http.post(`/contacts/show/${this.email}/add-tag`, {
                        tag: this.newTag
                    })
                    this.thistags.unshift(this.newTag)
                    this.tagFormShown = false
                    this.newTag = ''
                    this.$emit('tagsUpdated')
                }
                catch(error) {
                    this.success = false
                    this.$message.error(error.message)
                }
            }
        },
        async removeTag(tag) {
            try {
                await this.$http.post(`/contacts/show/${this.email}/remove-tag/${encodeURIComponent(encodeURIComponent(tag))}`)
                this.thistags.splice(this.thistags.indexOf(tag), 1)
                if(this.thistags.length < 13)
                    this.showAllTags = true
                this.$emit('tagsUpdated')
            }
            catch(error) {
                this.success = false
                this.$message.error(error.message)
            }
        }
    }

}
</script>

<style lang="scss" scoped>

    @import '~sass/variables.scss';

    .more-tags {
        border: 1px solid $grey-60;
        box-sizing: border-box;
        border-radius: 2px;
        font-weight: 600;
        color: $green-60;
        padding: 1px 0 1px 7px;
        font-size: 10.5px;
    }
</style>
