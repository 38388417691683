<template>
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules" slim>
        <div class="flex flex-col">
            <label v-if="labelText" :for="name" class="label" v-html="labelText"><a v-if="action" :href="action.url" class="float-right text-sail-500 txt-12 font-semibold hover:text-sail-700" target="_blank" @click="confirmAction" v-html="action.text" /></label>
            <div class="input-container" :class="{'error': errors.length > 0, 'disabled': disabled}">
                <icon v-if="icon" class="input-icon" size="small" :icon="icon" />
                <span v-if="prefix" class="prefix">{{ prefix }}</span>
                <input
                        :id="name"
                        v-model="localValue"
                        :name="name"
                        :type="inputType"
                        :placeholder="placeholderText"
                        :disabled="disabled"
                        :readonly="readonly"
                        @input="$emit('input', $event.target.value)"
                        @keyup.enter="button.text ? $emit('buttonAction') : null">
                <span v-if="suffix" class="suffix">{{ suffix }}</span>
                <div v-if="inputType === 'number'" class="flex flex-col">
                    <button class="counter up" @click="localValue++"><svg-vue icon="caret-up-solid" /></button>
                    <button class="counter down" @click="localValue--"><svg-vue icon="caret-down-solid" /></button>
                </div>
                <button v-if="button.text" class="input-button" :disabled="button.sending" @click="$emit('buttonAction')"><div v-if="button.sending" class="double-loader loader-sm loader-grey mr-2" />{{ button.text }}</button>
            </div>
            <div v-if="errors[0]" class="flex items-center space-x-1 mt-1">
                <icon icon="exclamation-circle-solid" class="text-messages-error-300" />
                <span class="text-messages-error-400 txt-12 font-semibold" v-html="errors[0]" />
            </div>
            <span v-if="explanationText" class="text-gray-700 txt-12 font-semibold mt-1" v-html="explanationText" />
        </div>
    </ValidationProvider>
</template>

<script>
export default {
    name: 'TextInput',

    props: {
        inputType: {
            type: String,
            default: 'text'
        },
        value: {
            type: [String, Number],
            required: true
        },
        name: {
            type: String,
            required: true
        },
        rules: {
            default: null
        },
        labelText: {
            type: String,
            default: null
        },
        action: {
            type: Object,
            validator(obj) {
                return 'text' in obj
            }
        },
        placeholderText: {
            type: String,
            default: null
        },
        explanationText: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: null
        },
        button: {
            type: Object,
            default() {
                return {
                    text: null,
                    sending: false
                }
            },
            validator(obj) {
                return 'text' in obj
            }
        },
        prefix: {
            type: String,
            default: null
        },
        suffix: {
            type: String,
            default: null
        }
    },

    data () {
        return {
            localValue: null
        }
    },

    watch: {
        value(val) {
            this.localValue = val
        }
    },

    mounted() {
        this.localValue = this.value
    },

    methods: {
        confirmAction() {
            this.$emit('confirmAction')
        },
    }
}
</script>

<style lang="scss" scoped>

    @import '~sass/variables.scss';

    .label {
        color: $base-font-color;
        font-size: 14px;
        line-height: 19px;
        font-weight: 600;
        margin-bottom: 8px;
        align-items: center;
    }

    .input-container {
        display: flex;
        align-items: center;
        border: 1px solid $grey-60;
        border-radius: 4px;
        overflow: hidden;

        &:hover {
            border-color: $grey-65;
        }

        &:focus-within:not(.error) {
            border-color: $green-60;
            box-shadow: 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #AFDD52;
        }

        &.disabled {
            border-color: $grey-50;
            background-color: $grey-10;

            .prefix, .suffix, .input-icon, .counter svg, .input-button {
                color: $grey-60;
            }

            .input-button {
                background-color: $grey-20;
            }
        }

        &.error {
            background-color: $error-10;
            border-color: $error-30;
        }

        .input-icon {
            color: $grey-70;
            margin-left: 8px;
        }

        .input-button {
            display: flex;
            align-items: center;
            background-color: $grey-20;
            color: $grey-80;
            font-size: 12px;
            line-height: 20px;
            font-weight: 600;
            padding: 8px;
            border-left: 1px solid $grey-60;
            box-sizing: border-box;
        }

        input {
            width: 100%;
            font-size: 14px;
            line-height: 20px;
            padding: 8px 12px;
            color: $grey-100;
            outline: none;
            overflow: hidden;

            &::placeholder {
                text-overflow: ellipsis;
                color: $grey-80;
            }

            &:disabled, &:read-only {
                color: $grey-60;
                background-color: $grey-10;

                &::placeholder {
                    color: $grey-60;
                }
            }
        }

        .prefix {
            font-size: 12px;
            line-height: 20px;
            font-weight: 600;
            color: $grey-70;
            margin-left: 12px;
        }

        .suffix {
            font-size: 12px;
            line-height: 20px;
            font-weight: 600;
            color: $grey-70;
            margin-right: 12px;
        }

        .counter {
            background-color: $grey-30;
            color: $grey-80;
            border-radius: 2px;
            width: 22px;
            height: 14px;

            svg {
                width: 10.5px;
                height: 14px;
                margin: auto;
            }

            &.up {
                margin: 3px 3px 2px 0px;
            }

            &.down {
                margin: 0px 3px 3px 0px;
            }
        }
    }
</style>
