<template>
    <div v-if="prevPage || nextPage">
        <ul class="ecm-pagination">
            <li>
                <button class="arrow-button left-arrow" :disabled="!prevPage" @click.prevent="changePage(currentPage - 1)"><icon key="left" icon="angle-left-solid" /></button>
            </li>

            <li>
                <button class="arrow-button right-arrow" :disabled="!nextPage" @click.prevent="changePage(currentPage + 1)"><icon key="right" icon="angle-right-solid" /></button>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'SimplePagination',

    props: ['prevPage', 'nextPage', 'currentPage'],

    methods: {
        changePage(pageNumber) {
            this.$emit('handlePageChange', pageNumber)
        }
    }
}
</script>

<style lang="scss" scoped>

    @import '~sass/variables.scss';

    ul.ecm-pagination {
        display: flex;
        overflow-x: auto;

        button:disabled {
            color: $grey-60;
            pointer-events: none;
        }

        .arrow-button {
            padding: 7px;
            border-radius: 3px;
            background-color: $grey-50;
            color: $grey-90;

            &.left-arrow {
                margin-right: 12px;
            }
            &.right-arrow {
                margin-left: 12px;
            }
        }
    }
</style>
