<template>

    <div class="space-y-2">
        <div v-for="connection in lists" :key="`list-${connection.id}`" v-accordion="'open'" class="tw-accordion card subscriber mb-2 flex flex-col items-start no-pad no-hover w-full">
            <div class="flex w-full p-3 cursor-pointer items-center" accordion-toggle>
                <span class="mr-3"><icon :key="`status-icon-${connection.status}`" class="flex-none" :class="STATUS_COLORS[connection.status]" :icon="STATUS_ICONS[connection.status]" /></span>
                <span class="txt-13 text-gray-1000 semibold flex-grow">{{ connection.owner.name }}</span>
                <icon class="caret-icon text-gray-900" icon="caret-down-solid" />
            </div>
            <div class="tw-accordion-content px-4 pb-4 w-full">
                <div class="ml-7">
                    <alert v-if="savingSuccess === connection.list_id" class="mb-5" type="success" :message="$t('appforms.subscriber.preferences-saved')" />
                    <alert v-if="unsubMessage" type="error" class="mb-5" :message="$t('appforms.subscriber.unsub-not-permitted')" />
                    <table class="txt-13 text-gray-1000 w-full">
                        <tr>
                            <td class="semibold text-gray-800 py-1 pr-12 w-1 whitespace-nowrap">
                                {{ $t('appforms.subscriber.list') }}
                            </td>
                            <td>
                                <a :href="'/contacts/' + connection.list_id" class="semibold">{{ connection.owner.name }}</a>
                            </td>
                        </tr>
                        <tr>
                            <td class="semibold text-gray-800 py-1 pr-12 w-1 whitespace-nowrap">
                                {{ $t('appforms.subscriber.status') }}
                            </td>
                            <td class="space-x-3">
                                <span>{{ $t('appforms.subscriber.sub_status.' + connection.status) }}</span>
                                <a v-if="connection.status === 1 || connection.status === 3" href="#" class="semibold" @click.stop.prevent="unsubscribe(connection.list_id)">{{ $t('appforms.subscriber.unsubscribe') }}</a>
                                <a v-else href="#" class="semibold" @click.stop.prevent="resubscribe(connection.list_id)">{{ $t('appforms.subscriber.subscribe') }}</a>
                            </td>
                        </tr>
                        <tr>
                            <td class="semibold text-gray-800 py-1 pr-12 w-1 whitespace-nowrap">
                                {{ $t('appforms.subscriber.sms_status') }}
                            </td>
                            <td class="space-x-3">
                                <span>{{ $t('appforms.subscriber.sub_status_sms.' + connection.sms_status) }}</span>
                                <a v-if="connection.sms_status === 1" href="#" class="semibold" @click.stop.prevent="unsubscribeSms(connection.list_id)">{{ $t('appforms.subscriber.unsubscribe') }}</a>
                                <a v-else href="#" class="semibold" @click.stop.prevent="resubscribeSms(connection.list_id)">{{ $t('appforms.subscriber.subscribe') }}</a>
                            </td>
                        </tr>
                        <tr>
                            <td class="semibold text-gray-800 py-1 pr-12 w-1 whitespace-nowrap">
                                {{ connection.status !== 2 ? $t('appforms.subscriber.signup-date') : $t('appforms.subscriber.unsubscribe-date') }}
                            </td>
                            <td>
                                {{ connection.status !== 2 ? getNumberDate(getIsoDate(connection.subscribed_at)) : getNumberDate(getIsoDate(connection.unsubscribed_at)) }}
                            </td>
                        </tr>
                        <tr>
                            <td class="semibold text-gray-800 py-1 pr-12 w-1 whitespace-nowrap">
                                {{ $t('appforms.subscriber.doi_date') }}
                            </td>
                            <td>
                                {{ getNumberDate(getIsoDate(doiDates[connection.list_id])) }}
                            </td>
                        </tr>
                        <tr>
                            <td class="semibold text-gray-800 py-1 pr-12 w-1 whitespace-nowrap">
                                {{ $t('appforms.subscriber.source') }}
                            </td>
                            <td>
                                {{ connection.source }}
                            </td>
                        </tr>
                        <tr v-if="Object.keys(connection.owner.groups).length > 0">
                            <td class="semibold text-gray-800 align-top py-1 pr-12 pt-3 w-1 whitespace-nowrap">
                                {{ $t('appforms.subscriber.preferences') }}
                            </td>
                            <td class="align-top pt-3 space-y-3">
                                <div v-for="group in connection.owner.groups" :key="'grp' + group.id" class="flex flex-col space-y-2">
                                    <template v-if="group.category.length > 0">
                                        <span class="txt-13 semibold text-gray-1000 mb-1">{{ group.name }}</span>
                                        <template v-if="group.type === 'checkbox'">
                                            <div v-for="category in group.category" :key="'cat' + category.id" class="tw-checkbox">
                                                <input :id="category.id" v-model="groups[connection.owner.id][group.id][category.name]" type="checkbox" :name="category.id" @change="changed(connection.list_id)">
                                                <label :for="category.id" class="txt-13">{{ category.name }}</label>
                                            </div>
                                        </template>
                                        <template v-else-if="group.type === 'radio'">
                                            <div v-for="category in group.category" :key="'cat' + category.id" class="tw-radio">
                                                <label :for="category.id">
                                                    <input :id="category.id" v-model="groups[connection.owner.id][group.id]" type="radio" :name="group.id" :value="category.name" @change="changed(connection.list_id)"> <span class="radiomark mr-3" /> <span class="txt-13">{{ category.name }}</span>
                                                </label>
                                            </div>
                                        </template>
                                        <template v-else-if="group.type === 'select'">
                                            <select id="invoiceCountry" v-model="groups[connection.owner.id][group.id]" class="tw-select txt-13" style="max-width: 250px" @change="changed(connection.list_id)">
                                                <option v-for="category in group.category" :key="'cat' + category.id" :value="category.name">{{ category.name }}</option>
                                            </select>
                                        </template>
                                        <template v-else-if="group.type === 'hidden'">
                                            <div class="input flex flex-col">
                                                <input id="contactEmail" v-model="groups[connection.owner.id][group.id]" type="text" class="tw-input" style="max-width: 250px" @keyup="changed(connection.list_id)">
                                            </div>
                                        </template>
                                    </template>
                                </div>
                                <div v-if="preferencesChanged.includes(connection.list_id)" class="flex mt-4 space-x-4">
                                    <button type="button" class="btn btn-primary" :disabled="savingPreferences === connection.list_id" @click="savePreferences(connection.owner.id)"><div v-if="savingPreferences === connection.list_id" class="double-loader loader-sm loader-grey mr-2" />{{ $t('appforms.subscriber.save-preferences') }}</button>
                                    <button type="button" class="btn btn-tertiary" :disabled="savingPreferences === connection.list_id" @click="resetPreferences(connection.list_id)">{{ $t('appforms.subscriber.cancel-preferences') }}</button>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import _ from 'lodash'
import toTemplate from '@mixins/toTemplate'

export default {

    name: 'SubLists',

    mixins: [toTemplate],

    props: ['email', 'lists', 'canUnsubscribe', 'doiDates'],

    data () {
        return {
            connections: {},
            groups: {},
            initgroups: {},
            preferencesChanged: [],
            savingPreferences: null,
            savingSuccess: null,
            STATUS_ICONS: {
                1: 'check-circle-solid',
                2: 'minus-circle-solid',
                3: 'check-circle-solid',
                4: 'times-circle-solid',
                5: 'exclamation-circle-solid',
                6: 'dot-circle-solid'
            },
            STATUS_COLORS: {
                1: 'text-green-400',
                2: 'text-gray-700',
                3: 'text-green-400',
                4: 'text-orange-500',
                5: 'text-black',
                6: 'text-gray-700'
            },
            unsubMessage: false
        }
    },

    created () {
        this.connections = this.lists
        _.forEach(this.connections, (connection) => {
            this.groups[connection.owner.id] = {}
            _.forEach(connection.owner.groups, (group) => {
                if(group.type === 'checkbox') {
                    this.groups[connection.owner.id][group.id] = this.getCheckboxValues(connection.groups, group.id)
                } else {
                    this.groups[connection.owner.id][group.id] = this.getValue(connection.groups, group.id)
                }
            })
        })

        this.initgroups = JSON.parse(JSON.stringify(this.groups))
    },

    methods: {
        getValue(vals, group) {
            if(!vals || !vals[group]) {
                return ''
            }

            return _.head(vals[group])
        },
        getCheckboxValues(vals, group) {
            var checkboxses = {}
            if(vals && vals[group]) {
                _.forEach(vals[group], (cat) => {
                    checkboxses[cat] = true
                })
            }
            return checkboxses
        },
        changed(list) {
            if(!this.preferencesChanged.includes(list))
                this.preferencesChanged.push(list)
            this.savingSuccess = null
        },
        unsubscribe(listId) {
            if (!this.canUnsubscribe){
                this.unsubMessage = true

                return
            }
            this.changeStatus(listId, 2)
        },
        resubscribe(listId) {
            this.changeStatus(listId, 1)
        },
        unsubscribeSms(listId) {
            if (!this.canUnsubscribe){
                this.unsubMessage = true

                return
            }
            this.changeStatusSms(listId, 0)
        },
        resubscribeSms(listId) {
            this.changeStatusSms(listId, 1)
        },
        async changeStatus(listId, status) {
            try {
                const response = await this.$http.get(`/contacts/update-status/${listId}/${this.email}/${status}`)
                this.connections[listId].status = status
                this.connections[listId].subscribed_at = response.data.subscriber.subscribed_at
                this.connections[listId].unsubscribed_at = response.data.subscriber.unsubscribed_at
            }
            catch(error) {
                //console.log(error)
            }
        },
        async changeStatusSms(listId, sms_status) {
            try {
                await this.$http.get(`/contacts/update-status-sms/${listId}/${this.email}/${sms_status}`)
                this.connections[listId].sms_status = sms_status
            }
            catch(error) {
                //console.log(error)
            }
        },
        async savePreferences(listId) {
            var saveGroups = {}
            saveGroups = {}
            _.forEach(this.groups[listId], (groups, groupId) => {
                if(typeof groups === 'object') {
                    var checks = []
                    _.forEach(groups, (checked, cat) => {
                        if(checked) {
                            checks.push(cat)
                        }
                    })
                    saveGroups[groupId] = checks
                } else {
                    if(groups && groups !== '') {
                        saveGroups[groupId] = [groups]
                    } else {
                        saveGroups[groupId] = []
                    }
                }
            })
            this.savingPreferences = listId
            this.savingSuccess = null

            try {
                await this.$http.post(`/spa/contacts/show/${this.email}/groups`, {
                    groups: saveGroups,
                    listId: listId,
                })
                this.savingPreferences = null
                this.savingSuccess = listId
                let index = this.preferencesChanged.indexOf(listId)
                if (index !== -1) {
                    this.preferencesChanged.splice(index, 1)
                }
            }
            catch(error) {
                // console.log(error)
            }

            this.savingPreferences = null
        },
        resetPreferences(list) {
            this.groups = JSON.parse(JSON.stringify(this.initgroups))

            let index = this.preferencesChanged.indexOf(list)
            if (index !== -1) {
                this.preferencesChanged.splice(index, 1)
            }
        }
    }

}
</script>