
<div>
    <div v-if="log && log.length" class="timeline">
        <div v-for="(event, index) in log" :key="`event-'${index}`" class="timeline-row">
            <div class="timeline-icon-line mr-4">
                <div class="text-white rounded-full flex items-center justify-center" :class="event.event === 'custom' && CUSTOM_EVENT_COLORS[event.category] ? CUSTOM_EVENT_COLORS[event.category]: EVENT_COLORS[event.event]" style="height: 26px; width: 26px">
                    <icon :icon="event.event === 'custom' && CUSTOM_EVENT_ICONS[event.category] ? CUSTOM_EVENT_ICONS[event.category]: EVENT_ICONS[event.event]" size="medium" />
                </div>
                <div class="line" />
            </div>
            <div class="timeline-content overflow-auto break-words" :class="{ 'timeline-content-long': event.event === 'click' }">
                <template v-if="event.event === 'pv'">
                    <p class="mt-1">
                        <span v-html="$t('appforms.subscriber.pv_event')" />
                        <a :href="`http://${event.url}`" target="_blank"> {{ event.url }}</a>
                    </p>
                </template>
                <template v-else-if="event.event === 'custom'">
                    <p class="mt-1">
                        <span v-html="$t('appforms.subscriber.custom_event')" />
                        <template v-if="event.action === 'Basket' || event.action === 'ShoptetBasket'">
                            <template v-if="getProductsFromBasket(event)">
                                <span> {{ $t('appforms.subscriber.basket.product_in_basket') }}</span>
                                <br>
                                <div class="mt-2">
                                    <template v-for="(product, i) in getProductsFromBasket(event)">
                                        <span v-if="i > 0" :key="`product-${index}-${i}-comma`">, </span>
                                        ● <a :key="`product-${index}-${i}`" target="_blank" :href="product.url ? product.url: '#'">{{ product.name ? product.name: $t('appforms.subscriber.basket.product_no_name') }}</a>
                                    </template>
                                </div>
                            </template>
                            <span v-else> {{ $t('appforms.subscriber.basket.empty_basket') }}</span>
                        </template>
                        <template v-else-if="event.category === 'ECM_PRODUCT_VIEW'">
                            <template v-if="event.product">
                                <span> {{ $t('appforms.subscriber.product_view.product_viewed') }}</span>
                                <br>
                                <div class="mt-2">
                                    <a :href="event.product.url ? event.product.url: '#'" target="_blank"> {{ event.product.name ? event.product.name: $t('appforms.subscriber.basket.product_no_name') }}</a>
                                </div>
                            </template>
                            <span v-else> {{ $t('appforms.subscriber.product_view.no_product') }}</span>
                        </template>
                        <span v-else> {{ event.category }} {{ event.action }} {{ event.label }} {{ event.property }} {{ event.value }}</span>
                    </p>
                </template>
                <template v-else-if="event.event === 'purchase'">
                    <p class="mt-1">
                        <span v-html="$t('appforms.subscriber.purchase_event', {amount: event.amount !== null ? numberToLocale(event.amount) : `(${$t('appforms.subscriber.activities.price_missing')})`, currency: event.amount ? applicationCurrencyCode : null})" />
                    </p>
                </template>
                <template v-else-if="event.campaign">
                    <p class="mt-1">
                        <span v-html="$t('appforms.subscriber.campaign_event.' + getEventName(event))" />
                        <a :href="'/reports/' + event.campaign_id" target="_blank"> {{ event.campaign.title }}</a>
                    </p>
                </template>
                <template v-else-if="event.pipeline && event.pipeline_action && event.pipeline_action.properties && event.pipeline_action.properties.name">
                    <p class="mt-1">
                        <span v-html="$t('appforms.subscriber.pipeline_event.' + getEventName(event))" />
                        <template v-if="event.event === 'unsub'">
                            {{ $t(`appforms.subscriber.pipeline_event.in_${event.pipeline_action.type}`) }}
                        </template>
                        <span class="text-gray-1000"> {{ event.pipeline_action.properties.name }}</span>
                        {{ $t('appforms.subscriber.pipeline_event.in_automation') }}
                        <a :href="'/automation/' + event.autoresponder_id"> {{ event.pipeline.name }}</a>
                    </p>
                </template>
                <template v-else-if="event.pipeline">
                    <p class="mt-1">
                        <span v-html="$t('appforms.subscriber.pipeline_event.' + getEventName(event))" />
                        {{ $t('appforms.subscriber.pipeline_event.in_automation') }}
                        <a :href="'/automation/' + event.autoresponder_id"> {{ event.pipeline.name }}</a>
                    </p>
                </template>
                <template v-else>
                    <p class="mt-1">
                        <span v-html="$t('appforms.subscriber.pipeline_event.' + getEventName(event))" />
                        <span class="text-gray-1000"> {{ $t('appforms.subscriber.pipeline_event.deleted') }}</span>
                    </p>
                </template>
                <p v-if="event.event === 'click'" class="mt-2 flex">
                    {{ $t('appforms.subscriber.click_event_link') }} <a target="_blank" :href="event.url" :title="event.url" class="ml-1 truncate" style="max-width: 35rem">{{ event.url }}</a>
                </p>
            </div>
            <div class="float-right whitespace-nowrap">
                <span class="text-gray-1000 txt-13 font-normal">{{ getEventDate(event) }}</span>
            </div>
        </div>
    
    </div>
    <div v-else class="panel py-12 text-center mt-2">
        <span class="text-gray-800 txt-14">{{ $t('appforms.subscriber.no-activity') }}</span>
    </div>
    <pagination v-if="pagination" class="mt-12" :total="pagination.total" :per-page="pagination.per_page" :current-page="pagination.current_page" :last-page="pagination.last_page" @handlePageChange="handlePageChange" />
</div>

