<template>
    <div>
        <!-- Agency stripe -->
        <div v-if="newAgencyBarVisible" class="py-1" :style="{backgroundColor: accountAgency.color}" />
        <nav class="ecomail-navbar">

            <div class="ec-container">

                <notifications :channel-name="notificationsChannel" />

                <div v-if="newAgencyBarVisible && !smallScreen">
                    <button type="button" class="flex items-center z-ultimate group" @click="toggleDropdown()">
                        <img v-if="currentAccount.logo" class="h-[36px] object-contain w-[75px] rounded-[3px]" :src="currentAccount.logo" alt="Current account logo">
                        <div v-else-if="currentAccount.logoPlaceholder" class="flex h-[36px] items-center justify-center w-[75px] rounded-[3px]" :style="currentAccount.logoPlaceholder">
                            <div>
                                {{ getInitials(currentAccount.name, 4) }}
                            </div>
                        </div>

                        <p class="font-medium ml-2 mr-1 xl:ml-4 xl:mr-2 text-gray-600 truncate max-w-[170px] txt-13 group-hover:text-white">
                            {{ currentAccount.name }}
                        </p>
                        <icon class="text-gray-600 group-hover:text-white" icon="angle-down-solid" />
                    </button>

                    <div v-if="dropdownOpen" ref="dropdown" class="agency-bar">
                        <div v-on-clickaway="closeDropdown" class="agency-bar__dropdown">
                            <div class="agency-bar__top rounded-t-[4px]">
                                <p class="agency-bar__description">
                                    {{ $t('navbar.in_current_account') }}
                                </p>
                                <div class="agency-bar__dropdown-selected">
                                    <img v-if="currentAccount.logo" class="object-contain rounded-[3px]" :src="currentAccount.logo" alt="Current account logo">
                                    <div v-else-if="currentAccount.logoPlaceholder" class="agency-bar__logo-placeholder" :style="currentAccount.logoPlaceholder">
                                        <div>{{ getInitials(currentAccount.name, 4) }}</div>
                                    </div>
                                    <div class="flex flex-col items-start ml-4">
                                        <strong class="agency-bar__account-name">
                                            {{ currentAccount.name }}
                                        </strong>
                                        <strong class="agency-bar__account-url">
                                            {{ currentAccount.url }}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                            <div class="agency-bar__dropdown-options">
                                <p class="agency-bar__description">
                                    {{ $t('navbar.log_into') }}
                                </p>
                                <div class="agency-bar__dropdown-options-list">
                                    <a
                                            v-if="loggedFromAgency"
                                            :href="`https://${agencyDomain}.ecomailapp.${topLevelDomain}`"
                                            class="agency-bar__dropdown-option">
                                        <img
                                                v-if="agencyLogo"
                                                class="object-contain rounded-[3px]"
                                                :src="agencyLogo"
                                                alt="Agency logo">
                                        <div v-else-if="agencyLogoPlaceholder" class="agency-bar__logo-placeholder" :style="agencyLogoPlaceholder">
                                            <div>
                                                {{ getInitials(agencyDisplayName, 4) }}
                                            </div>
                                        </div>
                                        <div class="flex flex-col items-start ml-4">
                                            <strong class="agency-bar__account-name">
                                                {{ agencyDisplayName }}
                                            </strong>
                                            <strong class="agency-bar__account-url">
                                                {{ `${agencyDomain}.ecomailapp.${topLevelDomain}` }}
                                            </strong>
                                        </div>
                                    </a>
                                    <a
                                            v-for="subaccount in subaccounts"
                                            :key="subaccount.name"
                                            :href="getSubaccountLoginUrl(subaccount.name)"
                                            class="agency-bar__dropdown-option"
                                            :class="isCurrentAccount(subaccount.name) && 'agency-bar__dropdown-option--selected'">
                                        <img
                                                v-if="getSubaccountLogo(subaccount)"
                                                class="object-contain rounded-[3px]"
                                                :src="getSubaccountLogo(subaccount)"
                                                alt="Subaccount logo">
                                        <div v-else-if="getSubaccountLogoPlaceholder(subaccount)" class="agency-bar__logo-placeholder" :style="getSubaccountLogoPlaceholder(subaccount)">
                                            <div>{{ getInitials(getSubaccountDisplayName(subaccount), 4) }}</div>
                                        </div>
                                        <div class="flex flex-col items-start ml-4">
                                            <strong class="agency-bar__account-name">
                                                {{ getSubaccountDisplayName(subaccount) }}
                                            </strong>
                                            <strong class="agency-bar__account-url">
                                                {{ getSubaccountUrl(subaccount.name) }}
                                            </strong>
                                        </div>
                                        <icon v-if="isCurrentAccount(subaccount.name)" icon="circle-check-solid" size="large" class="text-messages-info-300 ml-auto mr-2.5" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="logo-container">
                    <a href="/">
                        <img src="/images/logo-tiny.png" alt="Ecomail.cz">
                    </a>
                </div>

                <div class="content">
                    <div class="main-options">
                        <template v-for="(section, index) in sections">
                            <router-link v-if="isSpa && section.isSpa" :key="`section-${index}`" :to="{name: section.name}" class="main-link">
                                {{ section.label }}
                            </router-link>
                            <a v-else :key="`section-${index}`" :href="section.link" class="main-link" :class="{'active': active === section.active}">
                                {{ section.label }}
                            </a>
                        </template>
                        <span v-if="checkRole(user, 'manage-more') && user.role !== 'reader' && user.role !== 'support'" v-dropdown="'open'" class="tw-dropdown">
                            <a class="main-link" :class="{'more-section-active': moreSectionActive}" dropdown-toggle>
                                {{ $t('navbar.more') }}
                                <icon class="angle-icon" icon="angle-down-solid" />
                            </a>
                            <div class="tw-dropdown-items">
                                <div>
                                    <template v-for="(section, index) in moreSections">
                                        <template v-if="!section.hideForEnglishAccount || !account.isEnglishAccount">
                                            <a :key="`more-section-${index}`" :href="section.link">
                                                {{ section.label }}
                                            </a>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div class="user-options">
                        <div class="onboarding txt-13">
                            <a
                                    v-if="account.isEnglishAccount"
                                    href="#"
                                    @click.prevent="showOnboardingModal()">
                                <icon class="onboarding-icon" icon="circle-question-solid" size="medium" />
                                {{ $t('navbar.onboarding') }}
                            </a>
                            <a v-else target="_blank" :href="$t('common.support_link')">
                                <icon class="onboarding-icon" icon="circle-question-solid" size="medium" />
                                {{ $t('navbar.hint') }}
                            </a>
                        </div>
                        <div v-if="checkRole(user, 'manage-notifications') && user.role !== 'reader' && user.role !== 'support'" class="notifications-wrapper" :class="account && account.isEnglishAccount && 'notifications-wrapper--border-r'">
                            <notifications-menu :notifications="notifications" />
                        </div>
                        <user-menu :is-english-account="account.isEnglishAccount" :gravatar="gravatar" :user="user" :is-agency="account.isAgency" :is-spa="false" @logout="logout" />
                        <a href="#" class="search" @click.prevent="showSearchModal"><icon icon="search-solid" /></a>
                    </div>
                </div>

            </div>
        </nav>
        <nav class="ecomail-navbar-mobile">

            <div class="ec-container">

                <div class="logo-container">
                    <div :class="{'mobile-active': searchOpened || mobileNavOpened }">
                        <a href="/">
                            <img alt="Ecomail.cz" src="https://ecomail-assets.s3.amazonaws.com/old/images/logo-tiny.png">
                        </a>
                        <portal-target class="section-name" name="navbar-section-name" />
                    </div>
                    <div>
                        <div class="notifications-section" :class="{'mobile-active': searchOpened || mobileNavOpened }">
                            <ul v-if="checkRole(user, 'manage-notifications') && user.role !== 'reader' && user.role !== 'support'" class="notifications-wrapper">
                                <notifications-menu :notifications="notifications" />
                            </ul>
                        </div>
                        <div class="menu-divider" :class="{'mobile-active': searchOpened }" />
                        <button class="search-section" :class="{'mobile-active': mobileNavOpened, 'mobile-active-exact': searchOpened }" @click="toggleSearch">
                            <icon v-if="!searchOpened" icon="search-solid" size="large" />
                            <icon v-else key="close-left" class="close-icon" icon="times-solid-thin" size="large" />
                        </button>
                        <div class="menu-divider" :class="{'mobile-active': mobileNavOpened || searchOpened }" />
                        <button class="menu-section" :class="{'mobile-active': searchOpened, 'mobile-active-exact': mobileNavOpened }" @click="toggleNav">
                            <icon v-if="!mobileNavOpened" icon="hamburger" size="large" />
                            <icon v-else key="close-right" class="close-icon" icon="times-solid-thin" size="large" />
                        </button>
                    </div>
                </div>

                <div class="search-content" :class="{'search-open': searchOpened}">
                    <input v-model="query" class="tw-input" type="text" :placeholder="$t('navbar.search_placeholder')">
                    <button class="btn btn-primary btn-lg" @click="triggerSearch">{{ $t('navbar.search_button') }}</button>
                </div>

                <div class="content" :class="{'nav-open': mobileNavOpened}">
                    <div class="main-options">
                        <template v-for="(section, index) in sections">
                            <router-link v-if="isSpa && section.isSpa" :key="`section-${index}`" :to="{name: section.name}" class="main-link">
                                {{ section.label }}
                            </router-link>
                            <a v-else :key="`section-${index}`" :href="section.link" class="main-link" :class="{'active': active === section.active}">
                                {{ section.label }}
                            </a>
                        </template>
                        <!-- Agency sections -->
                        <span
                                v-if="newAgencyBarVisible && smallScreen"
                                class="tw-dropdown">
                            <a
                                    class="main-link"
                                    @click.prevent="toggleDropdown()">
                                <icon
                                        v-if="dropdownOpen"
                                        key="caret-down"
                                        class="caret-icon"
                                        icon="caret-down-solid" />
                                <icon
                                        v-else
                                        key="caret-right"
                                        class="caret-icon"
                                        icon="caret-right-solid" />
                                {{ $t('navbar.log_into') }}
                            </a>
                        </span>
                        <div v-if="dropdownOpen" class="tw-dropdown-items">
                            <a
                                    v-if="loggedFromAgency"
                                    :href="`https://${agencyDomain}.ecomailapp.${topLevelDomain}`">
                                {{ agencyDisplayName }}
                            </a>
                            <a
                                    v-for="subaccount in subaccounts"
                                    :key="subaccount.name"
                                    :href="`/account/login-as/${subaccount.name}`">
                                {{ getSubaccountDisplayName(subaccount) }}
                            </a>
                        </div>
                        <!-- -->
                        <span v-if="checkRole(user, 'manage-more') && user.role !== 'reader' && user.role !== 'support'" v-dropdown="'open'" class="tw-dropdown">
                            <a class="main-link" :class="{'more-section-active': moreSectionActive || moreSectionFocus}" dropdown-toggle @click.prevent="moreSectionFocus = ! moreSectionFocus">
                                <icon v-if="moreSectionFocus" key="caret-down" class="caret-icon" icon="caret-down-solid" />
                                <icon v-else key="caret-right" class="caret-icon" icon="caret-right-solid" />
                                {{ $t('navbar.more') }}
                            </a>
                            <div class="tw-dropdown-items">
                                <div>
                                    <template v-for="(section, index) in moreSections">
                                        <template v-if="!section.hideForEnglishAccount || !account.isEnglishAccount">
                                            <a :key="`more-section-${index}`" :href="section.link">
                                                {{ section.label }}
                                            </a>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </span>
                    </div>
                    <a class="help-link" :href="$t('common.support_link')" target="_blank"><icon class="help-icon" icon="question-circle-solid" />{{ $t('usermenu.help') }}</a>

                    <div class="user-options">
                        <a class="user-link" href="/account">
                            <img :src="gravatar" alt="Avatar">
                            <span>{{ user.email }}</span>
                            <icon icon="caret-right-solid" />
                        </a>
                        <a class="logout" href="#" @click.prevent="logout"><icon class="logout-icon" icon="unlock-solid" />{{ $t('usermenu.logout') }}</a>
                    </div>
                </div>

            </div>
        </nav>

        <OnboardingModal ref="onboardingModal" />
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import { mapState } from 'vuex'

import permissions from '@mixins/permissions'
import testing from '@mixins/testing'
import store from '@stores/index'
import Tracker from '@js/tracker'

import OnboardingModal from '@component/Modal/OnboardingModal.vue'

const getRandomNumber = ({from = 0, to = 100}) => {
    return Math.floor((Math.random() * to) + from)
}

const generateLogoPlaceholder = () => {
    const CONTRAST = 110
    const r = getRandomNumber({from: 40, to: 120})
    const g = getRandomNumber({from: 40, to: 120})
    const b = getRandomNumber({from: 40, to: 120})

    return {
        color: `rgb(${r}, ${g}, ${b})`,
        backgroundColor: `rgb(${r + CONTRAST}, ${g + CONTRAST}, ${b + CONTRAST})`,
    }
}

export default {
    name: 'Navbar',

    mixins: [clickaway, permissions, testing],

    props: ['notificationsChannel', 'notifications', 'active', 'isSpa'],

    components: {
        OnboardingModal,
    },

    store,

    data() {
        return {
            mobileNavOpened: false,
            searchOpened: false,
            moreSectionFocus: false,
            query: '',

            // Agency-related
            dropdownOpen: false,
            currentAccountUrl: window.location.host.split('.')[0],
            topLevelDomain: window.location.hostname.split('.').pop(),
        }
    },

    computed: {
        ...mapState(['account', 'user', 'accountAgency', 'loggedFromAgency', 'subaccounts', 'agencyDomain']),

        gravatar() {
            return this.user.avatar ? `${this.user.avatar}?width=100&height=100`: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/default-avatar.jpg'
        },

        // Agency logic
        newAgencyBarVisible() {
            if(this.account.isAgency) {
                return this.checkRole(this.user, 'manage-agency') && this.user.role !== 'reader' && this.user.role !== 'editor' && this.user.role !== 'support'
            }
            return this.loggedFromAgency
        },
        smallScreen() {
            return window.innerWidth < 1280
        },

        agencyDisplayName() {
            return this.accountAgency.name ?? ''
        },
        agencyLogo() {
            return this.accountAgency.logo ?? undefined
        },
        agencyLogoPlaceholder() {
            return this.accountAgency.logoPlaceholder
                ?? this.generateAndSaveAgencyLogoPlaceholder()
                ?? undefined
        },
        agencyColor() {
            return this.accountAgency.color ?? 'transparent'
        },

        currentSubaccount() {
            return this.subaccounts ? this.subaccounts.find(account => account.name === this.currentAccountUrl) : undefined
        },
        currentAccount() {
            if (this.account.isAgency) {
                return {
                    name: this.agencyDisplayName,
                    logo: this.agencyLogo,
                    logoPlaceholder: this.agencyLogoPlaceholder,
                    url: window.location.hostname,
                }
            }
            return {
                name: this.getSubaccountDisplayName(this.currentSubaccount) ?? this.currentAccountUrl,
                logo: this.getSubaccountLogo(this.currentSubaccount),
                logoPlaceholder: this.getSubaccountLogoPlaceholder(this.currentSubaccount),
                url: window.location.hostname,
            }
        },

        // Sections
        sections() {
            let sections = [
                {
                    isSpa: false,
                    active: 'dashboard',
                    label: this.$t('navbar.dashboard'),
                    link: '/'
                }
            ]
            if(this.user.role !== 'reader' && this.checkRole(this.user, 'manage-campaigns')) {
                sections.push(
                    {
                        isSpa: false,
                        label: this.$t('navbar.campaigns'),
                        name: 'campaignsIndex',
                        link: '/campaigns'
                    }
                )
            }
            if(this.user.role !== 'reader' && this.user.role !== 'support' && this.checkRole(this.user, 'manage-contacts')) {
                sections.push(
                    {
                        isSpa: false,
                        label: this.$t('navbar.contacts'),
                        name: 'subscribersLists',
                        link: '/contacts'
                    }
                )
            }
            if(this.checkRole(this.user, 'manage-reports')) {
                sections.push(
                    {
                        isSpa: false,
                        label: this.$t('navbar.reports'),
                        name: 'reportsList',
                        link: '/reports'
                    }
                )
            }
            if(this.user.role !== 'reader' && this.user.role !== 'support' && this.checkRole(this.user, 'manage-automations')) {
                sections.push(
                    {
                        isSpa: true,
                        label: this.$t('navbar.automations'),
                        name: 'automationsIndex',
                        link: '/automation'
                    }
                )
            }
            if(this.user.role !== 'reader' && this.checkRole(this.user, 'manage-templates')) {
                sections.push(
                    {
                        isSpa: false,
                        label: this.$t('navbar.templates'),
                        name: 'templatesIndex',
                        link: '/templates'
                    }
                )
            }

            return sections
        },
        moreSections() {
            let moreSections = []

            if(this.checkRole(this.user, 'manage-more-facebook')) {
                moreSections.push(
                    {
                        label: this.$t('navbar.dropdown.audiences'),
                        name: 'moreCrmAudiences',
                        link: '/account/crm-audiences'
                    }
                )
            }
            if(!this.account.isEnglishAccount && this.checkRole(this.user, 'manage-more-sklik')) {
                moreSections.push(
                    {
                        label: this.$t('navbar.dropdown.sklik'),
                        name: 'moreSklik',
                        link: '/account/sklik',
                        hideForEnglishAccount: true
                    }
                )
            }
            if(this.checkRole(this.user, 'manage-more-products')) {
                moreSections.push(
                    {
                        label: this.$t('navbar.dropdown.product_feeds'),
                        name: 'moreProductFeeds',
                        link: '/account/product-feeds'
                    }
                )
            }
            if(this.checkRole(this.user, 'manage-more-data')) {
                moreSections.push(
                    {
                        label: this.$t('navbar.dropdown.data_feeds'),
                        name: 'moreDataFeeds',
                        link: '/account/data-feeds'
                    }
                )
            }
            if(this.checkRole(this.user, 'manage-more-rss')) {
                moreSections.push(
                    {
                        label: this.$t('navbar.dropdown.rss'),
                        name: 'moreRss',
                        link: '/account/rss-feeds'
                    }
                )
            }
            if(this.checkRole(this.user, 'manage-more-coupons')) {
                moreSections.push(
                    {
                        label: this.$t('navbar.dropdown.coupons'),
                        name: 'moreCoupons',
                        link: '/account/coupons'
                    }
                )
            }
            if(this.checkRole(this.user, 'manage-more-verify')) {
                moreSections.push(
                    {
                        label: this.$t('navbar.dropdown.verify_emails'),
                        name: 'moreVerifyEmails',
                        link: '/account/verify-emails'
                    }
                )
            }
            if(this.checkRole(this.user, 'manage-more-transactional')) {
                moreSections.push(
                    {
                        label: this.$t('navbar.dropdown.transactionals'),
                        name: 'transactionalOverview',
                        link: '/transactional/transactional'
                    }
                )
            }

            return moreSections
        },
        moreSectionActive() {
            const routeSection = this.$route.path.split('/')
            if(routeSection[1] === 'account' && ['crm-audiences', 'product-feeds', 'data-feeds', 'coupons', 'sklik', 'verify-emails', 'rss-feeds'].includes(routeSection[2]))
                return true
            return routeSection[1] === 'transactional' || routeSection[1] === 'ai'
        },
    },

    watch: {
        '$route'() {
            this.mobileNavOpened = false
        }
    },

    created() {
        store.dispatch('loadAccountData')
    },

    methods: {
        getSubaccountLoginUrl(subaccountName) {
            const route = `/account/login-as/${subaccountName}`

            if(this.loggedFromAgency && this.agencyDomain) {
                return `https://${this.agencyDomain}.ecomailapp.${this.topLevelDomain}${route}`
            }

            return route
        },
        showSearchModal() {
            this.$root.$refs['searchModal'].showModal()
            new Tracker().trackEvent('BUTTON_CLICKED', {
                scope: 'search',
                name: 'search icon'
            })
        },
        showOnboardingModal() {
            this.$refs['onboardingModal'].showModal()
        },
        toggleSearch() {
            this.searchOpened = !this.searchOpened
            if(this.searchOpened)
                this.mobileNavOpened = false
        },
        toggleNav() {
            this.mobileNavOpened = !this.mobileNavOpened
            if(this.mobileNavOpened)
                this.searchOpened = false
        },
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen
        },
        closeDropdown() {
            this.dropdownOpen = false
        },
        triggerSearch() {
            if(!this.query) {
                return
            }
            window.location.href = encodeURI(`/search?query=${encodeURIComponent(this.query)}`)
        },
        async logout() {
            try {
                await this.$http.post('/logout')
                window.location.href = '/auth/login'
            }
            catch(error) {
                console.error(error)
            }
        },

        // Agency logic
        generateAndSaveSubaccountLogoPlaceholder(subaccountName) {
            const logoPlaceholder = generateLogoPlaceholder()
            this.$http.post(
                `/spa/account/manage-agency/save-subaccount-settings/${subaccountName}`,
                {
                    logoPlaceholder: logoPlaceholder,
                },
            )
            return logoPlaceholder
        },
        generateAndSaveAgencyLogoPlaceholder() {
            const logoPlaceholder = generateLogoPlaceholder()
            this.$http.post(
                '/spa/account/manage-agency/save-settings',
                {
                    logoPlaceholder: logoPlaceholder,
                },
            )
            return logoPlaceholder
        },
        getInitials(string, maxInitials) {
            if(!string) {
                return ''
            }

            return string.split(' ').map((n, index) => {
                if(!maxInitials) {
                    return n[0]
                }

                if(maxInitials <= index) {
                    return undefined
                }
                return n[0]
            }).join('').toUpperCase()
        },

        getSubaccountDisplayName(subaccount) {
            return subaccount?.features?.subaccountSettings?.displayName ?? subaccount?.name
        },
        getSubaccountUrl(subaccountName) {
            return `${subaccountName}.ecomailapp.${this.topLevelDomain}`
        },
        getSubaccountLogo(subaccount) {
            return subaccount?.features?.agencySubaccountSettings?.logo ?? undefined
        },
        getSubaccountLogoPlaceholder(subaccount) {
            return subaccount?.features?.agencySubaccountSettings?.logoPlaceholder
            ?? this.generateAndSaveSubaccountLogoPlaceholder(subaccount?.name)
            ?? undefined
        },

        isCurrentAccount(accountName) {
            return this.currentAccountUrl === accountName
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '~sass/variables.scss';

    .agency-bar {
        @apply absolute ;
        top: 10px;

      .ec-container {
            @apply pt-0 relative;
        }

        &__top {
        @apply p-4 bg-white;
    }

        &__dropdown {
            @apply relative z-[96] absolute;
        }
        &__dropdown-selected {
            @apply flex pr-3 items-center w-full;

            &--bottom-rounded {
                @apply rounded-b-[4px];
            }
        }

        &__dropdown-options {
            @apply flex flex-col border p-4 rounded-b-[4px] bg-gray-200;
            border-top: 1px solid #e0e0e0;
            border-left: 1px solid white;
            border-right: 1px solid white;
            border-bottom: 1px solid white;
          box-shadow: 0 0 0 0 rgba(0,0,0,0),0 0 0 0 rgba(0,0,0,0),0px 4px 6px -2px rgba(16,24,40,0.03), 0px 12px 16px -4px rgba(16,24,40,0.08)
        }

        &__dropdown-options-list {
            @apply flex flex-col gap-y-4;
        }

        &__description {
            @apply uppercase text-gray-800 font-medium mb-[11px];

            font-size: 12px;
        }

        &__dropdown-option {
            @apply flex items-center rounded-[6px] pr-[18px];

            &:not(:last-child) {
                @apply mb-1;
            }


            &--selected, &:hover {
                @apply bg-white rounded-[4px];

                box-shadow: 0px 0px 0px 4px #fff;
            }
        }

        img {
            @apply h-[48px] w-[100px];
        }

        &__logo-placeholder {
            @apply object-cover h-[48px] w-[100px] rounded-[3px] flex;

            div {
                @apply m-auto;
            }
        }

        &__account-name {
            @apply text-gray-1000 font-medium;

            font-size: 16px;
        }

        &__account-url {
            @apply text-gray-800 font-medium;

            font-size: 14px;
        }
    }

    .ecomail-navbar-mobile {
        background-color: $grey-100;
        height: $navbar-height;
        font-size: 13px;
        position: relative;
        z-index: 10;
        font-family: Inter, sans-serif;

        .ec-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0 auto;
            overflow: hidden;
            border-radius: 4px;
        }

        .logo-container {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            height: 60px;

            > div {
                display: flex;
                align-items: center;

                &:first-child {
                    width: 100%;
                    height: 100%;
                    padding-left: 20px;

                    &.mobile-active {
                        border-bottom: 1px solid $grey-90;
                    }
                }

                &:last-child {
                    height: 100%;

                    .notifications-section, .search-section {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        margin-right: -1px;

                        &.mobile-active {
                            border-bottom: 1px solid $grey-90;
                        }
                    }

                    .menu-divider {
                        border-left: 1px solid $grey-90;
                        height: 66%;
                        margin: auto;

                        &.mobile-active {
                            height: 100%;
                        }
                    }

                    .notifications-section, .search-section, .menu-section {
                        &.mobile-active-exact {
                            background-color: $grey-100;
                        }
                    }
                }
            }

            img {
                width: 25px;
            }

            .section-name {
                display: block;
                color: $white-text;
                margin-left: 16px;
                font-size: 16px;
            }

            .notifications-wrapper {
                display: block;
                padding: 0 20px;
            }

            .search-section {
                display: flex;
                padding: 0 20px;
                align-items: center;
                justify-content: flex-end;
                color: $grey-60;
            }

            .menu-section {
                display: inline-block;
                padding: 0 20px;
                color: $grey-60;
                height: 100%;
            }

            .close-icon {
                    color: $white-text;
                }
        }

        .search-content {
            display: none;
            width: 100%;
            margin: auto;
            padding: 40px;

            &.search-open {
                display: block;
                background-color: $grey-100;
            }

            .tw-input {
                width: 100%;
                height: 44px;
            }

            button {
                margin-top: 24px;
                float: right;
            }
        }

        .content {
            display: none;
            width: 100%;

            &.nav-open {
                display: block;
                background-color: $grey-100;
            }

            .main-options {
                padding: 6px 0 12px;
                margin: 0 40px;
                border-bottom: 1px solid $grey-90;
            }
        }

        .main-link {
            display: block;
            padding: 16px 20px;
            cursor: pointer;
            color: $grey-60;
            text-decoration: none;
            font-weight: 500;

            .caret-icon {
                float: left;
                margin-left: -8px;
                margin-right: 4px;
            }

            &.active, &.router-link-active, &.more-section-active {
                color: $white-text;
                background-color: #282828;
                border-radius: 4px;
            }
        }

        .help-link {
            display: block;
            padding: 20px 20px;
            margin: 0 40px;
            cursor: pointer;
            color: $grey-60;
            
            .help-icon {
                color: $green-40;
                float: left;
                margin-left: -8px;
                margin-right: 16px;
            }
        }

        .user-options {
            background-color: #282828;
            padding: 0 40px 8px;

            .user-link {
                display: flex;
                padding: 16px 20px;
                color: $grey-60;
                align-items: center;
                border-bottom: 1px solid $grey-90;

                > *:not(:last-child) {
                    margin-right: 8px;
                }
                
                img {
                    margin-left: -12px;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }
            }

            .logout {
                display: block;
                padding: 16px 20px;
                color: $grey-60;

                .logout-icon {
                    color: $grey-70;
                    float: left;
                    margin-left: -8px;
                    margin-right: 16px;
                }
            }
        }

        .tw-dropdown-items {
            position: relative;
            background-color: $grey-100;
            border: none;
            box-shadow: none;

            >div {
                width: 100%;
            }

            a {
                display: block;
                color: $grey-60;
                padding: 16px 0;
                margin: 8px 40px 0;

                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
    .ecomail-navbar {
        display: none;
        font-family: Inter, sans-serif;
    }

    @media (min-width: 1280px) {
        .ecomail-navbar-mobile {
            display: none;
        }
        .ecomail-navbar {
            display: block;
            padding: 0 20px;
            background-color: $grey-100;
            height: $navbar-height;
            font-size: 13px;
            position: relative;
            z-index: 10;

            .ec-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin: 0 auto;
            }

            .logo-container {
                flex-shrink: 0;
                padding: 0;
                margin-right: 20px;
                width: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 60px;

                > a {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    img {
                        width: 25px;
                    }
                }
            }

            .content {
                display: flex;
                flex-grow: 1;
                align-items: center;
                width: auto;

                &.nav-open {
                    display: block;
                    background-color: $grey-100;
                }

                .angle-icon {
                    float: right;
                    margin-left: 4px;
                    color: $green-40;
                }

                .main-options {
                    display: flex;
                    flex-grow: 1;
                }
            }

            .main-link {
                cursor: pointer;
                display: flex;
                color: $grey-60;
                padding: 20px 14px 17px;
                border-bottom: 3px solid transparent;
                text-decoration: none;
                font-weight: 500;

                &:hover {
                    color: $white-text;
                }

                &.active, &.router-link-active, &.more-section-active {
                    border-bottom: 3px solid $green-40;
                    color: $white-text;
                }
            }

            .tw-dropdown.open {
                .main-link {
                    text-decoration: none;
                    font-weight: 500;
                }
                .tw-dropdown-items {
                    width: 100%;
                    position: absolute;
                    display: block;
                    left: 0;
                    top: 50px;
                    z-index: 100;
                    min-width: 165px;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
                    border-radius: 3px;

                    a {
                        display: block;
                        color: $grey-100;
                        padding: 7px 0 9px 14px;
                    }

                    > div {
                        overflow: hidden;
                        border-radius: 3px;
                        background-color: #fff;

                        a {
                            display: block;
                            font-size: 12px;
                            color: $grey-100;
                            padding: 7px 0 9px 14px;

                            &:hover {
                                color: $grey-100;
                                background-color: $grey-30;
                            }
                        }
                    }
                }
            }

            .user-options {
                display: flex;
                align-items: center;
                height: 50px;

                .onboarding {
                    @apply py-[7px] pr-3 border-r border-gray-900 text-gray-600 items-center font-medium;

                    a {
                        @apply flex items-center gap-1 text-gray-600 hover:text-white whitespace-nowrap;
                        &:hover {
                            & .onboarding-icon {
                                @apply text-[#82CF1F];
                            }
                        }
                    }

                    .svg-wrapper {
                        @apply text-green-400;
                    }
                }

                .notifications-wrapper {
                    @apply pl-[13px] pr-[25px] pt-[10px] pb-1;

                    &--border-r {
                       @apply border-r border-gray-900;
                    }
                }

                .search {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin-left: 24px;
                    color: $grey-60;

                    &:hover {
                        color: $white-text;
                    }
                }
            }
        }
    }

    @media (min-width: 1536px) {
        .ecomail-navbar {
            .main-link {
                padding: 20px 24px 17px;
            }
        }
    }

    a {
        text-decoration: none;
    }
</style>
