<template>
    <div>
        <transition-group class="notifications-holder" tag="div" style="background-color: transparent" name="slide">
            <notification v-for="notification in notificationsActive" :id="notification.id" :key="`notification-${notification.id}`" :message="notification.header" :type="notification.style" />
        </transition-group>
    </div>
</template>

<script>
import Pusher from 'pusher-js'
import store from './store/store'
import Notification from '@component/Notifications/Notification.vue'
import { mapState } from 'vuex'

export default {
    name: 'Notifications',

    props: {
        channelName: {
            type: String,
            required: true
        }
    },

    components: {
        Notification
    },

    store,

    computed: {
        ...mapState([
            'notificationsActive'
        ])
    },
  
    created() {
        let pusher = new Pusher('d9227f1530fb2760f117', {
            cluster: 'eu',
            secret: 'dd261a845fc434b77a4b',
            appId: '199657',
        })

        pusher = pusher.subscribe(this.channelName)
        pusher.bind('new-notification', (data) => {
            this.$store.dispatch('fetchNotifications', true)
            this.$store.dispatch('addActiveNotification', data)
        })

    }
}
</script>

<style lang="scss">

    @import "~sass/variables.scss";
    @import '~sass/components/notifications.scss';
    @import "~sass/components/icon-wrapper.scss";
    
    .slide-leave-active {
        transition: 0.5s;
    }
    .slide-enter-active {
        transition: 0.5s
    }
    .slide-enter {
        transform: translate(100%, 0);
    }
    .slide-leave-to {
        opacity: 0;
    }
</style>
